import {Label, mergeStyleSets, Stack, useTheme} from "@fluentui/react";
import * as React from "react";
import {useMemo} from "react";
import {Bom} from "../../../types/configuration";
import {buildValueWithUnit} from "../../../context/formatUtils";
import {ITheme} from "@fluentui/react/dist/react";

interface OverviewProps {
  overviewParameters: Bom | undefined,
}

function overviewStyle(theme: ITheme) {
  return mergeStyleSets({
    overviewItems: {
      padding: "0 0 10px 0"
    }
  })
}


export function Overview({overviewParameters}: OverviewProps) {
  const theme = useTheme();
  const {overviewItems} = useMemo(() => overviewStyle(theme), [theme]);
  return <Stack.Item className={overviewItems}>
    <Stack.Item> {overviewParameters && overviewParameters?.parameter.map(parameter =>
      <Stack horizontal horizontalAlign="space-between">
        <Label>{parameter.translatedName}</Label>
        <Label>{buildValueWithUnit(parameter)}</Label>
      </Stack>)}
    </Stack.Item>
  </Stack.Item>
}

