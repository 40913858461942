import {RefObject, useEffect, useMemo, useRef, useState} from "react";
import {equals} from "ramda";

type ElementDimension = {
  height: number,
  width: number,
}

type OutOfScrollStore<T extends HTMLElement> = {
  ref: RefObject<T>,
  outOfScroll: boolean,
} & ElementDimension

export function useOutOfScroll<T extends HTMLElement>(endOfElement: boolean = true): OutOfScrollStore<T> {
  const ref = useRef<T>(null);
  const [space, setSpace] = useState<ElementDimension>({height: -1, width: -1});
  const [outOfScroll, setOutOfScroll] = useState<boolean>(false);

  useEffect(() => {
    /**
     * Wrapper function to fill ref element into event listener
     * @param refElement the actual ref<T> as HTML Element
     */
    function onScroll(refElement: T) {
      return function () {
        /**
         * returns true if the element is out of the viewable area (out of scroll)
         * @param element T
         */
        function scrollOutOfView(element: T) {
          const {height, y} = element.getBoundingClientRect();
          if (endOfElement) {
            if (outOfScroll) {
              return y + space.height <= 0;
            }
            return y + height <= 0;
          }
          return y <= 0;
        }

        /**
         * Gets the width and height of the given element T
         * @param element T
         */
        function getSpace(element: T): ElementDimension | undefined {
          const {height, width} = element.getBoundingClientRect();
          if (!equals(space.height, height) || !equals(space.width, width)) {
            return {height, width};
          }
          return undefined
        }

        const isOutOfScrollArea = scrollOutOfView(refElement);
        if (!equals(isOutOfScrollArea, outOfScroll)) {
          setOutOfScroll(isOutOfScrollArea);
        } else {
          const newSpace = getSpace(refElement);
          if (newSpace && !isOutOfScrollArea) {
            setSpace(newSpace);
          }
        }
      }
    }

    if (ref && ref.current) {
      const onScrollEvent = onScroll(ref.current);
      window.addEventListener("scroll", onScrollEvent);
      window.addEventListener("resize", onScrollEvent);
      return () => {
        window.removeEventListener("scroll", onScrollEvent);
        window.removeEventListener("resize", onScrollEvent);
      }
    }
  }, [space, outOfScroll]);

  return useMemo(() => ({
    ...space,
    ref,
    outOfScroll,
  }), [ref, outOfScroll, space]);
}