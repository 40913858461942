import { any, equals, isEmpty, map, path, reduce } from "ramda";
import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client";
import { Bom, BomParameter, SelectedParameters } from "../types/configuration";

function toBomParameter(parameter: ParameterTO): BomParameter {
  return {
    id: parameter.name,
    value: path(["selectedValues", 0, "value"], parameter),
    translatedValue: path(["selectedValues", 0, "translatedValue"], parameter),
    viewport: parameter.viewPort,
    translatedName: parameter.translatedName,
    mandatory: parameter.mandatory,
    translatedUnit: parameter.displayUnit?.translatedUnit
  };
}

export function toBom(acc: Bom[], container: ContainerTO): Bom[] {
  const initialBom = {
    id: container.name,
    longText: container.longText,
    children: !isEmpty(container.children)
      ? reduce(toBom, [], container.children)
      : undefined,
    parameter: map(toBomParameter, container.parameters),
  };

  return [...acc, initialBom];
}

/**
 * Determines if any selection by the user is in the active parameterTO array
 * @param parameters the parameters to search with
 * @returns boolean true if any selection had taken place
 */
export function anySelectionByUser(parameters: ParameterTO[]): boolean {
  const selectionSources = map(
    (param) => path(["selectedValues", 0, "selectionSource"], param),
    parameters
  );
  return any((val) => equals("SET_BY_USER", val), selectionSources);
}

/**
 * Function to determine all selected parameters to the given reducer
 * @param acc the selected parameters accumulator
 * @param parameter the parameter to reduce
 * @returns selectedParameters the selected parameters object
 */
export function toSelectedParameters(
  acc: SelectedParameters,
  parameter: ParameterTO
): SelectedParameters {
  return {
    ...acc,
    [parameter.name]: path(["selectedValues", 0, "value"], parameter),
  };
}
