import {addIndex, equals, length, lt, map} from "ramda";
import {useMemo, useState} from "react";
import {mergeStyleSets, PrimaryButton, Stack, useTheme} from "@fluentui/react";
import {mediaQuery} from "../../../theme";
import {useTranslation} from "react-i18next";
import {ContainerTO} from "@encoway/c-services-js-client";
import {ComponentFactory} from "@encoway/react-configurator";
import classNames from "classnames";
import {useOutOfScroll} from "../../../hooks/useOutOfScroll";
import {ITheme} from "@fluentui/react/dist/react";
import {ConfiguratorComponentProps} from "../../../types/configuration";

function tabStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      counterReset: "tabCount",
      flexDirection: "row",
      selectors: {
        "&.sticky": {
          zIndex: "10",
          position: "fixed",
          top: 0,
        }
      }
    },
    button: {
      padding: "10px 5px",
      minWidth: "initial",
      height: "initial",
      display: "flex",
      justifyContent: "center",
      counterIncrement: "tabCount",
      flexGrow: "0",
      flex: "1",
      selectors: {
        ":before": {
          content: "counter(tabCount) '.'",
          paddingRight: "2px",
        },
        "&.past": {},
        "&.selected, &:focus": {
          color: theme.palette.white,
          backgroundColor: theme.palette.themePrimary,
        },
      },
      ":not(.selected)": {
        [mediaQuery.md]: {
          flex: ".5",
        },
        "span": {
          [mediaQuery.md]: {
            display: "none",
          },
        }
      }
    },
    content: {
      flexDirection: "row",
      flexWrap: "wrap",
      minHeight: "80vh",
      padding: "0 40px",
    },
    viewport: {
      width: "100%",
    },
    nextButtonContainer: {
      flex: "1 1 100%",
      alignSelf: "end",
      display: "grid",
      gridTemplateColumns: "1fr",
      padding: "50px 30%",
      gap: "20px",
    },
    nextButton: {
      padding: "20px 5px",
    }
  });
}

export function ConfTab({data, ...props}: ConfiguratorComponentProps<ContainerTO>) {
  const {t} = useTranslation();
  const theme = useTheme();
  const tabStyled = useMemo(() => tabStyle(theme), [theme]);
  const [selected, setSelected] = useState(0);
  const {ref, outOfScroll, width} = useOutOfScroll<HTMLDivElement>(false);

  const selectedViewPort = useMemo(
    () => ComponentFactory.instanceOf(
      data.children[selected].viewPort!,
      {...props, data: data.children[selected]}
    ),
    [data, selected]
  );

  const nextStep = (current: number) =>
    lt(current, length(data.children) - 1) ? current + 1 : 0;

  if (data.children.length === 1){
    return <Stack className={tabStyled.content}>
      {selectedViewPort}
    </Stack>
  }
  return <div ref={ref}>
    <Stack className={classNames(tabStyled.root, {sticky: outOfScroll})} style={{width: outOfScroll ? width : "auto"}}>
      {data.children
        && addIndex<ContainerTO>(map)((container: ContainerTO, index: number) =>
            <PrimaryButton
              key={`conf-tab-${container.id}`}
              className={classNames(tabStyled.button, {selected: equals(index, selected), past: lt(index, selected)})}
              onClick={() => setSelected(index)}>
              {container.translatedName}
            </PrimaryButton>,
          data.children)}
    </Stack>
    <Stack className={tabStyled.content}>
      <Stack.Item className={tabStyled.viewport}>
        {selectedViewPort}
      </Stack.Item>
      <div className={tabStyled.nextButtonContainer}>
        <PrimaryButton className={tabStyled.nextButton} onClick={() => setSelected(current => nextStep(current))}>
          {lt(selected, length(data.children) - 1)
            ? t("t:configuration:next_step")
            : t("t:configuration:return_to_first_step")}
        </PrimaryButton>
      </div>
    </Stack>
  </div>
}
