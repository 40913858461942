import React, {createContext, ReactNode, useContext, useMemo, useState} from "react";
import {ConfigurationContext} from "./useConfiguration";
import {equals, none, reduce} from "ramda";
import {Settings} from "../settings";
import {Products} from "../types/product";
import {BomParameter} from "../types/configuration";

export const BomContext = createContext<Products>({});
export const BomProvider = BomContext.Provider;

export function useBom(): Products {
  const {bom} = useContext(ConfigurationContext);
  const [bomProducts] = useState<Products>({});
  //@ts-ignore
  const ids = useMemo(() => bom ? reduce<BomParameter, (string | number)[]>((acc, {value}) => {
    if (value && none(equals(value), Settings.studio.boms.noProduct)) {
      return [...acc, value]
    }
    return acc;
  }, [], bom[0].parameter || []) : [], [bom]);

  return useMemo(() => bomProducts, [bomProducts]);
}

export function BomStore({children}: { children: ReactNode }) {
  return <BomProvider value={useBom()}>
    {children}
  </BomProvider>
}