import {mergeStyleSets, useTheme} from "@fluentui/react";
import {Configurator} from "./Configurator";
import {Sidebar} from "./bom/Sidebar";
import {UserInfoArea} from "./userInfoArea/UserInfoArea";
import {mediaQuery} from "../../theme";
import {ITheme} from "@fluentui/react/dist/react";
import {useMemo} from "react";

function configurationStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      display: "grid",
      gridTemplateColumns: "0.75fr 1.75fr 0.75fr",
      gap: "0",
      [mediaQuery.xl]: {
        gridTemplateColumns: "1fr",
      }
    },
    userInfoArea: {
      backgroundColor: theme.palette.neutralLighterAlt,
      [mediaQuery.xl]: {
        order: 0,
      },
    },
    configurator: {
      borderLeft: `1px solid ${theme.palette.neutralLight}`,
      borderRight: `1px solid ${theme.palette.neutralLight}`,
      [mediaQuery.xl]: {
        order: 1
      },
    },
    bom: {
      backgroundColor: theme.palette.neutralLighterAlt,
      [mediaQuery.xl]: {
        order: 0,
      },
    }
  });
}

export function Configuration() {
  const theme = useTheme();
  const configurationStyled = useMemo(() => configurationStyle(theme), [theme]);
  const {root, userInfoArea, configurator, bom} = configurationStyled;

  return <div className={root}>
    <div className={userInfoArea}>
      <UserInfoArea/>
    </div>
    <div className={configurator}>
      <Configurator/>
    </div>
    <div className={bom}>
      <Sidebar/>
    </div>
  </div>
}