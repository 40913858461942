import {CatalogService, Characteristics, Filter, Selection} from "@encoway/c-services-js-client";
import React, {createContext, ReactNode, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {forEach, isEmpty, map, path, reduce, uniq} from "ramda";
import {toExtendedCharacteristic} from "./productUtils";
import {Instance, Settings} from "../settings";
import {Products} from "../types/product";

export type ProductState = {
  products: Products,

  getProducts(ids: string | number | (string | number)[]): Promise<Products>
}

export const ProductContext = createContext<ProductState>({
  products: {},
  getProducts: async function () {
    throw new Error("get not initialized");
  },
});

export const ProductProvider = ProductContext.Provider;

function useProduct(): ProductState {
  const {i18n} = useTranslation();
  const catalogService = useMemo(() =>
      new CatalogService(Instance.http, Settings.showroom.url, i18n.language),
    [i18n.language]);
  const [products, setProducts] = useState<{ [lang: string]: Products }>({});

  async function fetchProducts(ids: string[]): Promise<Products> {
    let productsFilter = Filter.productsFilter();
    forEach((id) => productsFilter.id(id + ""), ids);
    let selection = new Selection().filter(productsFilter).limit(1000).characteristics(new Characteristics().all());
    const productsResult = await catalogService.products(selection);
    return reduce((acc, product) => {
      const characteristicValues = reduce(toExtendedCharacteristic(product.characteristicValues), {}, productsResult.characteristics)
      return {...acc, [product.id]: {...product, characteristicValues}};
    }, {}, productsResult.products);
  }

  async function getProducts(ids: string | number | (string | number)[]): Promise<Products> {
    if (isEmpty(ids)) {
      throw new Error("can not empty array products");
    }
    const uIds = Array.isArray(ids) ? uniq(map(id => id + "", ids)) : [ids + ""];
    if (path<string>([i18n.language], products)) {
      const {cacheIds, fetchIds} = reduce((acc: { cacheIds: string[], fetchIds: string[] }, id: string) => {
        if (products[i18n.language][id]) {
          return {...acc, cacheIds: [...acc.cacheIds, id]};
        }
        return {...acc, fetchIds: [...acc.fetchIds, id]};
      }, {cacheIds: [], fetchIds: []}, uIds);
      const cachedProducts = reduce((acc, _id) => ({...acc, [_id]: products[i18n.language][_id]}), {}, cacheIds);
      if (isEmpty(fetchIds)) {
        return cachedProducts;
      }
      const newProducts = await fetchProducts(fetchIds);
      setProducts(prev => ({...prev, [i18n.language]: {...prev[i18n.language], ...newProducts}}));
      return {...newProducts, ...cachedProducts}
    }
    const newProducts = await fetchProducts(uIds);
    setProducts(prev => ({...prev, [i18n.language]: {...prev[i18n.language], ...newProducts}}));
    return newProducts;
  }

  return useMemo(() => ({
    products: products[i18n.language],
    getProducts,
  }), [products, i18n.language]);

}

export function ProductStore({children}: { children: ReactNode }) {
  return <ProductProvider value={useProduct()}>
    {children}
  </ProductProvider>
}