import {ReactNode} from "react";
import {AppStore} from "./context/useApp";
import {ProductStore} from "./context/useProduct";
import {ConfigurationStore} from "./context/useConfiguration";
import {BomStore} from "./context/useBom";

export function Provider({children}: { children: ReactNode }) {
  return <ProductStore>
    <ConfigurationStore>
      <BomStore>
        <AppStore>
          {children}
        </AppStore>
      </BomStore>
    </ConfigurationStore>
  </ProductStore>
}