import {ParameterTO} from "@encoway/c-services-js-client";
import {ITheme, mergeStyleSets, Text, useTheme} from "@fluentui/react";
import {useMemo} from "react";
import {ConfiguratorComponentProps} from "../../../types/configuration";
import {Settings} from "../../../settings";

function styledParameter(theme: ITheme) {
  return mergeStyleSets({
    root: {
      margin: "15px 0 7.5px 0",
    }
  })
}

export function ConfParameter({data}: ConfiguratorComponentProps<ParameterTO>) {
  const theme = useTheme();
  const {root} = useMemo(() => styledParameter(theme), [theme]);

  return <div className={root}>
    {Settings.customizing.noParameterTO.some(viewport => { return viewport === data.viewPort}) ? null : <Text variant="mediumPlus">{data.translatedName || data.name}</Text> }
  </div>
}