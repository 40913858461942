import {mergeStyleSets, Stack, Text, useTheme} from "@fluentui/react";
import classNames from "classnames";
import {useContext, useMemo} from "react";
import {mediaQuery} from "../../../theme";
import {useOutOfScroll} from "../../../hooks/useOutOfScroll";
import {ITheme} from "@fluentui/react/dist/react";
import {Section} from "../../../components/Section";
import {AppContext} from "../../../context/useApp";
import {useTranslation} from "react-i18next";

function sidebarStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      padding: "70px 40px",
      selectors: {
        "&.sticky": {
          zIndex: "10",
          top: 0,
          position: "fixed",
          [mediaQuery.xl]: {
            position: "initial",
          }
        }
      }
    },
    container: {
      justifyContent: "center",
      selectors: {
        ":first-child": {
          textAlign: "center",
          padding: "0 0 10px 0",
        },
        "label": {
          fontWeight: "bold"
        },
      }
    }
  })
}

function retrieveUserHelpText() {
  const {userHelpText} = useContext(AppContext);

  let helpTextAsInnerHTML = {
    __html: ""
  };

  if (typeof userHelpText !== "undefined") {
    helpTextAsInnerHTML = {
      __html: userHelpText
    };
  }

  return helpTextAsInnerHTML;
}

export function UserInfoArea() {


  const helpTextAsInnerHTML = retrieveUserHelpText();

  const theme = useTheme();
  const {t} = useTranslation();
  const {root, container} = useMemo(() => sidebarStyle(theme), [theme]);
  const {ref, outOfScroll, width, height} = useOutOfScroll<HTMLDivElement>(false);

  return <>
    <div ref={ref}>
      <Stack className={classNames(root, {"sticky": outOfScroll})}
             style={{width: outOfScroll ? width : "auto", minHeight: outOfScroll ? height : "auto"}}>
        <Stack className={container}>
          <Section isInSidebar={true} variant="xLarge" label={t("t:userInfoArea.heading")}/>
        </Stack>
        <Text variant={"medium"} >
          <span dangerouslySetInnerHTML={helpTextAsInnerHTML} />
        </Text>
      </Stack>
    </div>
  </>
}