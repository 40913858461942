import * as React from 'react';
import { Checkbox } from '@fluentui/react';
import parse from 'html-react-parser';


interface CheckboxWithTextProps {
  linkText: string;
  checked: boolean;
  onChange: (event: React.FormEvent<HTMLInputElement | HTMLElement> | undefined, checked?: boolean | undefined) => void;
}

const CheckboxWithText = (props: CheckboxWithTextProps) => {

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox checked={props.checked} onChange={props.onChange} />
      {parse(props.linkText)}
    </div>
  );
};

export default CheckboxWithText;