import {Outlet} from "react-router-dom";
import {mergeStyleSets} from "@fluentui/react";

const configuratorStyled = mergeStyleSets({
  root: {
    minHeight: "80vh",
  }
})
export function Layout() {
  return <div id="configurator" className={configuratorStyled.root}>
    <Outlet/>
  </div>;

}