import {useContext, useEffect} from "react";
import {ConfigurationContext} from "../../context/useConfiguration";
import {useNavigate, useParams} from "react-router-dom";
import {equals} from "ramda";
import {MessageBar, MessageBarType} from "@fluentui/react";
import {PROGRESS, useProgress} from "../../hooks/useProgress";
import {Configuration} from "./Configuration";
import {ConfigurationRoutes} from "../../types/routes";
import {ProductContext} from "../../context/useProduct";
import {useTranslation} from "react-i18next";

export function LoadConfiguration() {
  const {i18n} = useTranslation();
  const {cfg, guiTO, actions} = useContext(ConfigurationContext);
  const {getProducts} = useContext(ProductContext);
  const {session, article, load} = useParams<ConfigurationRoutes>();
  const progress = useProgress();
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      try {
        progress.set(PROGRESS.LOADING);
        let config = {cfg, guiTO};
        if (load) {
          config = await actions.loadSaved(load);
        } else if (article && !session) {
          config = await actions.start(article);
        } else if (session) {
          if (!cfg || !equals(session, cfg.id())) {
            config = await actions.loadSession(session);
          }
          progress.set(PROGRESS.LOADED);
        }
        if (article) {
          navigate(`/configuration/${article}/${config.cfg!.id()}`, {replace: true});
        }
      } catch (e: any) {
        progress.set(PROGRESS.ERROR, `${e.message} - Configuration could not be loaded.`);
      }
    })();
  }, [article, session, load]);

  useEffect(() => {
    if(article) {
      getProducts(article).then();
    }
  }, [article, i18n.language]);

  if (progress.error) {
    return <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={false}
      onDismiss={() => navigate("/")}
      dismissButtonAriaLabel="back"
    >
      {progress.message}
    </MessageBar>
  }

  if (cfg && progress.loaded) {
    return <Configuration/>
  }

  return null
}