import {Instance} from "../settings";
import {getConfigurationBlob} from "./configurationService";
import {BomMailState} from "../pages/configurator/bom/useContactRequest";

export type BomMailTO = {
  leadDetails: BomMailState[];
  productId: string;
  frontendHost:string;
  reference: string;
  showPrices?: boolean;
};

export type ProjectDTO = {
  projectName: string;
  projectId: string;
  solution: string;
};

export async function sendProject(
    articleName: string,
    configurationId: string,
    language: string
): Promise<ProjectDTO> {
  const blob = await getConfigurationBlob(configurationId, language);
  const {data} = await Instance.axios.post<ProjectDTO>(`qcs-api/projects/`, {
    projectName: articleName,
    projectId: configurationId,
    solution: blob,
  });
  return data;
}

export async function sendMail(
    articleName: string,
    configurationId: string,
    language: string,
    bomMail: BomMailState,
    showPrices: boolean
): Promise<void> {
  const {projectId} = await sendProject(
      articleName,
      configurationId,
      language
  );
  const dto: BomMailTO = {
    leadDetails: [
      ...Object.keys(bomMail).map((key) => {
        return {key: key, value: bomMail[key]};
      }),
    ],
    productId: articleName,
    frontendHost: window.location.origin,
    reference: projectId,
    showPrices,
  };
  await Instance.axios.post<string>(
      `qcs-api/mailing/${projectId}/confirmation`,
      {
        ...dto,
        projectId,
      },
      {
        headers: {
          locale: language,
        },
      }
  );
}
