import {Bom, BomParameter} from "../types/configuration";
import {TextField} from "@fluentui/react";
import {BomMailState} from "../pages/configurator/bom/useContactRequest";
import {Section} from "./Section";
import * as React from "react";
import {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ConsentCheckbox from "../pages/configurator/components/ConsentCheckbox";

interface DynamicRequestProps{
    requestContentParameter: Bom | undefined
    onChange(value: Partial<BomMailState>): void,
    bomStyled: any,
    setInfoMissing: Dispatch<SetStateAction<boolean>>
}

type IdValuePair = { [key: string]: string | number };

const mandatoryValues: IdValuePair = {};

export function DynamicRequestContent({requestContentParameter, onChange, bomStyled, setInfoMissing}: DynamicRequestProps)
{
    const {t} = useTranslation();
    const [checked, setChecked] = useState(false);
    function setMandatoryValue(id: string, value: string) {
        if (mandatoryValues[id] !== undefined) {
            mandatoryValues[id] = value;
        }
    }

    useEffect(() => {
        if (requestContentParameter) {
            requestContentParameter.children?.forEach((child) => {
                child.parameter
                  .filter((component) => component.mandatory === true)
                  .forEach((param) => {
                      mandatoryValues[param.id] = "";
                  });
            });
        }
    }, []);

    useEffect(() => {
        setInfoMissing(
          Object.keys(mandatoryValues).some((key) => mandatoryValues[key] === "") || !checked
        );
    }, [mandatoryValues, checked]);

    function onInputChange(key: string) {
        return function (
          event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string | undefined
        ) {
            onChange({ [key]: newValue || "" });

            setMandatoryValue(key, newValue || "");
            setInfoMissing(
              Object.keys(mandatoryValues).some((key) => mandatoryValues[key] === "") || !checked
            );
        };
    }

    function createComponent(component: BomParameter) {
        let viewPort;
        switch (component.viewport) {
        case "inputField":
            viewPort = (
              <TextField
                required={component.mandatory}
                label={component.translatedName}
                onChange={onInputChange(component.id)}
              />
            );
            break;
        }
        return viewPort;
    }

    function createSection(section: Bom){
        return(
                section.id === "consent" ? (<div className={bomStyled.singleRow}>
                    <ConsentCheckbox linkText={section.longText || ""} checked={checked} onChange={()=>setChecked(!checked)}/>
                </div>) : (<div className={section.id === "singlecolumn" ? bomStyled.singleRow : bomStyled.input}>
                    {section.parameter.map(component => {return createComponent(component)})}
                </div>)
        );
    }

    return requestContentParameter ?
        (<div>
            <Section label={t("t:bom.mail.heading")}/>
            {requestContentParameter.children?.map(section => {return createSection(section)})}
        </div>) : null;
}
