import {Label, mergeStyleSets, PrimaryButton, Stack, useTheme} from "@fluentui/react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import * as React from "react";
import {useContext, useEffect, useMemo} from "react";
import {equals, find, pathOr} from "ramda";
import {mediaQuery} from "../../../theme";
import {useOutOfScroll} from "../../../hooks/useOutOfScroll";
import {ITheme} from "@fluentui/react/dist/react";
import {Dialog} from "../../../components/Dialog/Dialog";
import {RequestForm} from "./RequestForm";
import {Overview} from "./Overview";
import {Section} from "../../../components/Section";
import {PROGRESS} from "../../../hooks/useProgress";
import {BomSave} from "./BomSave";
import {useContactRequest} from "./useContactRequest";
import {ConfigurationContext} from "../../../context/useConfiguration";
import {Settings} from "../../../settings";
import {Bom as BomType} from "../../../types/configuration";
import {Constants} from "@encoway/react-configurator";

function sidebarStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      padding: "70px 40px",
      selectors: {
        "&.sticky": {
          zIndex: "10",
          top: 0,
          position: "fixed",
          [mediaQuery.xl]: {
            position: "initial",
          }
        }
      }
    },
    container: {
      justifyContent: "center",
      selectors: {
        ":first-child": {
          textAlign: "center",
          padding: "0 0 10px 0",
        },
        "label": {
          fontWeight: "bold"
        },
      }
    },
    button: {
      marginTop: "10%",
      padding: "25px 0",
      position: "relative",
      selectors: {
        "span": {
          fontWeight: "bold",
        }

      }
    },
    contactNoteStyle: {
      textAlign: "center",
      marginTop: "15%",
      border: "solid",
      borderColor: theme.palette.themePrimary,
      background: "transparent"
    }
  })
}

export function Sidebar() {

  const theme = useTheme();
  const {t} = useTranslation();
  const {bom, guiTO, eventBus} = useContext(ConfigurationContext);

  const {
    mailOpen,
    setOpenMail,
    mailInputs,
    onSend,
    progress,
    setMailInputs,
    emailSend,
    setEmailSend
  } = useContactRequest();
  const {root, container, button, contactNoteStyle} = useMemo(() => sidebarStyle(theme), [theme]);
  const {ref, outOfScroll, width, height} = useOutOfScroll<HTMLDivElement>(false);
  const overviewParameters = find<BomType>(child => equals(child.id, Settings.studio.boms.overview), pathOr([], [0, "children"], bom));
  const formParameters = find<BomType>(child => equals(child.id, Settings.studio.boms.form), pathOr([], [0, "children"], bom));
  const ready = guiTO?.rootContainer.readyState === "READY";

  useEffect(() => {
    return eventBus.onValue((e: any) => {
      if (equals(e.event, Constants.Events.UpdateState)) {
        setEmailSend(false);
      }
    })

  }, [eventBus])

  return <>
    <Dialog isOpen={mailOpen} onDismiss={() => setOpenMail(false)}>
      <RequestForm
        requestFormParameter={formParameters}
        bomMail={mailInputs}
        onDismiss={() => setOpenMail(false)}
        onChange={(value) => setMailInputs({...mailInputs, ...value})}
        onSend={onSend}
      />
    </Dialog>
    <Dialog isOpen={progress.loaded} onDismiss={() => progress.set(PROGRESS.NOT_LOADED)}>
      <BomSave
        onDismiss={() => progress.set(PROGRESS.NOT_LOADED)}
        projectId={progress.message}
      />
    </Dialog>
    <div ref={ref}>
      <Stack className={classNames(root, {"sticky": outOfScroll})}
             style={{width: outOfScroll ? width : "auto", minHeight: outOfScroll ? height : "auto"}}>
        <Stack className={container}>
          <Section isInSidebar={true} variant="xLarge" label={t("t:bom.name")}/>
        </Stack>
        <Stack className={container}>
          <Overview overviewParameters={overviewParameters}/>
        </Stack>
        <Stack className={container}>
          {emailSend ? (
              <Stack>
                <Label className={contactNoteStyle}>{t("t:bom.contact_note")}</Label>
              </Stack>
            ) :
            (
                <Stack>
                  <Label className={contactNoteStyle}>{t("t:bom.next_steps")}</Label>
                  <PrimaryButton onClick={() => setOpenMail(true)} className={classNames(button)} disabled={!ready}>
                    {t("t:bom.mail_configuration")}
                  </PrimaryButton>
                </Stack>
            )}
            </Stack>
      </Stack>
    </div>
  </>
}