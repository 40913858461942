import {mergeStyleSets, Spinner, SpinnerSize} from "@fluentui/react";

const loadingStyled = mergeStyleSets({
  root: {
    display: "flex",
    height: "80vh",
    alignItems: "center",
    justifyContent: "center",
  },
  spinner: {
    margin: "0 0 10vh 0",
  }
})

export function Loading() {
  return <div className={loadingStyled.root}>
    <Spinner className={loadingStyled.spinner} size={SpinnerSize.large}/>
  </div>
}