import {isEmpty, map} from "ramda";
import {ContainerTO} from "@encoway/c-services-js-client";
import {ComponentFactory} from "@encoway/react-configurator";
import {mergeStyleSets, Stack, useTheme} from "@fluentui/react";
import {Section} from "../../../components/Section";
import {ITheme} from "@fluentui/react/dist/react";
import {useMemo} from "react";

function sectionStyle(theme: ITheme) {
  return mergeStyleSets({
    section: {
      margin: "30px 0 0 0",
    }
  })
}

export function ConfSection({data, ...props}: { data: ContainerTO, props: any }) {
  const theme = useTheme();
  const sectionStyled = useMemo(() => sectionStyle(theme), [theme]);

  return <Stack key={data.id}>
    {isEmpty(data.children) && <Stack className={sectionStyled.section}>
        <Section label={data.translatedName}/>
    </Stack>}
    {data.children
      && map(container =>
          <ConfSection key={container.id} {...{...props, data: container}} />,
        data.children)
    }
    {data.parameters
      && map(container => ComponentFactory.instanceOf(
          container.viewPort!,
          {
            ...props,
            data: container,
            key: container.id,
          }),
        data.parameters)
    }
  </Stack>
}