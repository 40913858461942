import {Section} from "../../../components/Section";
import {ITheme, mergeStyleSets, Stack, Text, useTheme} from "@fluentui/react";
import * as React from "react";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {DialogButtons} from "../../../components/Dialog/DialogButtons";
import {mediaQuery} from "../../../theme";
import {useParams} from "react-router-dom";
import {useClipboard} from "../../../hooks/useClipboard";
import {ConfigurationRoutes} from "../../../types/routes";

function bomSaveStyle(theme: ITheme) {
  return mergeStyleSets({
      root: {
        maxWidth: "540px",
        padding: "40px",
        selectors: {
          [mediaQuery.lg]: {
            width: "100%",
          }
        }
      },
      subHeading: {
        padding: "20px 20px 0 20px",
      },
      url: {
        fontWeight: "500",
        padding: "30px 0 40px 0",
        wordBreak: "break-all",
        whiteSpace: "pre-wrap",
      }
    }
  );
}

const uri = `http://${window.location.hostname}${window.location.port !== "" ? `:${window.location.port}` : ""}/#/configuration/load`;

export function BomSave({onDismiss, projectId}: { onDismiss(): void, projectId: string }) {
  const {article} = useParams<ConfigurationRoutes>();
  const theme = useTheme();
  const {t} = useTranslation();
  const bomSaveStyled = useMemo(() => bomSaveStyle(theme), [theme]);
  const {copy} = useClipboard();
  const link = `${uri}/${article}/${projectId}`;

  return <Stack className={bomSaveStyled.root}>
    <Section label={t("t:bom.save.heading")}/>
    <div className={bomSaveStyled.subHeading}>
      <Text variant="medium">{t("t:bom.save.sub_heading")}</Text>
      <div className={bomSaveStyled.url}>
        {`${uri}/${article}/${projectId}`}
      </div>
    </div>
    <DialogButtons
      onCancel={() => copy(link)}
      cancelText={t("t:common.copy")}
      okText={t("t:common.ok")}
      onOk={onDismiss}
    />
  </Stack>
}