import {createTheme, Theme} from "@fluentui/react";
import {MediaQuery} from "./types/theme";

export const mediaQuery: MediaQuery = {
  sm: "@media only screen and (max-width:576px)",
  md: "@media only screen and (max-width:768px)",
  lg: "@media only screen and (max-width:992px)",
  xl: "@media only screen and (max-width:1200px)",
  xxl: "@media only screen and (max-width:1800px)",
}

/**
 * Application Theme
 * overwritten from useApp imported from studio characteristic "theme" in content block "application"
 */
export const appTheme: Theme = createTheme();