import {Language} from "@encoway/c-services-js-client";
import {Instance} from "../settings";

export async function fetchLanguages(language: string): Promise<Language[]> {
  const res = await Instance.axios.get<{ languages: Language[] }>(`api/catalog/languages`, {headers: {"Accept-Language": language}});
  return res.data.languages;
}

export async function fetchResource<T>(uri: string, language: string): Promise<T> {
  const translationRes = await Instance.axios.get<T>(uri, {headers: {"Accept-Language": language}});
  return translationRes.data;
}