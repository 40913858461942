import React from "react";
import {HashRouter, Navigate, Route, Routes} from "react-router-dom";
import {Layout} from "./pages/layout/Layout";
import {LoadConfiguration} from "./pages/configurator/LoadConfiguration";
import {Error} from "./pages/layout/Error";
import {Provider} from "./Provider";
import {LanguageMock} from "./pages/layout/LanguageMock";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Provider>
        <LanguageMock/>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Layout/>}>
              <Route path="*" element={<Error/>}/>
              <Route index element={<Navigate to={"/configuration/HAUPTMODELL"}/>}/>
              <Route path="configuration">
                <Route path="load/:article/:load" element={<LoadConfiguration/>}/>
                <Route path=":article/:session" element={<LoadConfiguration/>}/>
                <Route path=":article" element={<LoadConfiguration/>}/>
                <Route index element={<LoadConfiguration/>}/>
              </Route>
            </Route>
          </Routes>
        </HashRouter>
      </Provider>
    </div>
  );
}

export default App;