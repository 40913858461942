import {MessageBar, MessageBarType} from "@fluentui/react";
import {useNavigate} from "react-router-dom";
import {ReactNode} from "react";

export function Error({component}: { component?: ReactNode | string }) {
  const navigate = useNavigate();
  return <MessageBar
    messageBarType={MessageBarType.error}
    isMultiline={false}
    onDismiss={() => navigate("/")}
    dismissButtonAriaLabel="back"
  >
    {component
      ? component
      : "An error occurred. Please go back to our index page"}
  </MessageBar>
}