import {IFontStyles, mergeStyleSets, Text, useTheme} from "@fluentui/react";
import {ITheme} from "@fluentui/react/dist/react";
import {useMemo} from "react";
import classNames from "classnames";

type SectionProps = {
    className?: string,
    label: string,
    variant?: keyof IFontStyles,
    borderColor?: string,

    isInSidebar?: boolean
}

function sectionStyle(theme: ITheme) {
    return mergeStyleSets({
        text: {
            padding: "0 0 2px 0",
            borderBottom: `2px solid ${theme.palette.themeLighter}`,
        },
        sidebarBackground: {

        },
        sidebarHeaderFont: {
            fontWeight: "bold"
        }
    });
}

export function Section({className, label, variant, isInSidebar}: SectionProps) {
    const theme = useTheme();
    const sectionStyled = useMemo(() => sectionStyle(theme), [theme]);

    const wrapperClasses = isInSidebar ?
        classNames(sectionStyled.text, sectionStyled.sidebarBackground, className):
        classNames(sectionStyled.text, className);

    const textClasses = isInSidebar ?
        sectionStyled.sidebarHeaderFont:
        "";

    return <div className={wrapperClasses}>
        <Text className={textClasses} variant={variant || "xLarge"}>{label}</Text>
    </div>
}