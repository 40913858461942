import {ITheme} from "@fluentui/react/dist/react";
import {mergeStyleSets, useTheme} from "@fluentui/react";
import {useMemo} from "react";

function lineBreakStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      padding: "30px 0",
    },
    innerRoot: {
      borderTop: `2px solid ${theme.palette.themeLighter}`,
    }
  })
}

export function LineBreak() {
  const theme = useTheme();
  const linebreakStyled = useMemo(() => lineBreakStyle(theme), [theme]);
  return <div className={linebreakStyled.root}>
    <div className={linebreakStyled.innerRoot}></div>
  </div>
}