import React, {ReactNode, useMemo} from "react";
import {IconButton, mergeStyleSets, Modal, useTheme} from "@fluentui/react";
import {ITheme} from "@fluentui/react/dist/react";
import {mediaQuery} from "../../theme";

type DialogProps = {
  children: ReactNode,
  isOpen: boolean | undefined,
  onDismiss: () => void
}

function dialogStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      maxWidth: "750px",
      selectors: {
        [mediaQuery.lg]: {
         maxWidth: "initial",
        }
      }
    },
    icon: {
      top: "10px",
      right: "30px",
      position: "absolute",
    },
  });
}

export function Dialog({children, isOpen, onDismiss}: DialogProps) {
  const theme = useTheme();
  const dialogStyled = useMemo(() => dialogStyle(theme), [theme]);

  return <>
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <div className={dialogStyled.root}>
        <IconButton
          className={dialogStyled.icon}
          iconProps={{iconName: "Cancel"}}
          ariaLabel="Close dialog"
          onClick={onDismiss}
        />
        {children}
      </div>
    </Modal>
  </>
};