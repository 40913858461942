import {useTranslation} from "react-i18next";
import {mergeStyleSets, useTheme} from "@fluentui/react";
import {AppContext} from "../../context/useApp";
import {useContext, useMemo, useRef} from "react";
import {Portal} from "../../components/Portal";
import {ITheme} from "@fluentui/react/dist/react";

function languageStyle(theme: ITheme) {
    return mergeStyleSets({
        languageDropDownStyle: {
            color: theme.palette.white,
        },
        text: {
            cursor: "pointer",
            textTransform: "uppercase",
            color: theme.palette.white,
            fontWeight: "500",
            selectors: {
                ":not(:last-child)": {
                    padding: "0 5px 0 0",
                },
                "&.selected": {
                    color: theme.palette.themeLight,
                    fontWeight: "500",
                }
            },
        },
    });
}

export function LanguageMock() {
    const {i18n} = useTranslation();
    const theme = useTheme();
    const languageStyled = useMemo(() => languageStyle(theme), [theme]);
    const {languages} = useContext(AppContext);
    const oldLanguage = useRef<string>(i18n.language);
    const language = languages[i18n.language] || languages[oldLanguage.current];

    function onSelectionChanged(lang: string) {
        return function (): void {
            i18n.changeLanguage(lang).then();
        }
    }

    return <Portal id="encoway-mock-inject">
    </Portal>
}