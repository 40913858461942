import {Characteristic, CharacteristicValue} from "@encoway/c-services-js-client";
import {any, equals, isEmpty, keys, reduce} from "ramda";
import {Categories, Category} from "../types/category";
import {ExtendedCharacteristic, Products} from "../types/product";

export function toExtendedCharacteristic(characteristic: { [characteristicId: string]: CharacteristicValue }) {
  return function (acc: {}, ele: Characteristic): ExtendedCharacteristic {
    return {
      ...acc,
      [ele.id]: {
        ...characteristic[ele.id],
        ...ele,
      }
    }
  }
}

export function matchCategory(id: string | number, group: Categories): Partial<Category> {
  return reduce((acc: Partial<Category>, groupId): Partial<Category> => {
    if (isEmpty(acc)) {
      if (equals(id, group[groupId].id)) {
        return group[groupId];
      }
      return matchCategory(id, group[groupId].categories)
    }
    return acc;
  }, {}, keys(group));
}

export function matchProduct(id: keyof Products, group: Categories): Partial<Category> {
  return reduce((acc: Partial<Category>, groupId: keyof Products): Partial<Category> => {
    if (isEmpty(acc)) {
      //@ts-ignore
      if (any(equals(id), group[groupId].products)) {
        return group[groupId];
      }
      return matchProduct(id, group[groupId].categories)
    }
    return acc;
  }, {}, keys(group));
}