import {useCallback, useState} from "react";
import {equals} from "ramda";

export enum PROGRESS {
  NOT_LOADED,
  LOADING,
  LOADED,
  ERROR,
}

type ProgressStatus = {
  message: string,
  notLoaded: boolean,
  loading: boolean,
  loaded: boolean,
  error: boolean,
}

export type ProgressStore = {
  set(progress: PROGRESS, message?: string): void
} & ProgressStatus

export function useProgress(): ProgressStore {
  const [progress, setProgress] = useState<ProgressStatus>({
    message: "",
    notLoaded: true,
    loading: false,
    loaded: false,
    error: false,
  });

  const set = useCallback(function (value: PROGRESS, message = "") {
    const eq = equals(value);
    setProgress({
      message,
      notLoaded: eq(PROGRESS.NOT_LOADED),
      loading: eq(PROGRESS.LOADING),
      loaded: eq(PROGRESS.LOADED),
      error: eq(PROGRESS.ERROR)
    });
  }, [setProgress]);

  return {
    ...progress,
    set
  }
}