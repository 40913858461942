import {RefObject, useEffect, useRef, useState} from "react";

interface CopyLinkStore<T extends HTMLElement> {
  copy(newLink: string): Promise<void>

  close(): void,

  copied: boolean,
  ref: RefObject<T>
}

export function useClipboard<T extends HTMLElement>(timeout = 3000): CopyLinkStore<T> {
  const [copied, setCopied] = useState<boolean>(false);
  const ref = useRef<T>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  async function copy(link: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(link);
    } catch {
      let tempElement = document.createElement('textarea');
      tempElement.value = link;
      tempElement.style.display = "none";
      tempElement.style.position = "fixed";
      document.body.appendChild(tempElement);
      tempElement.select();
      document.execCommand('copy');
      document.body.removeChild(tempElement);
    }
    setCopied(true);
    return new Promise(res => {
      const _timer = setTimeout(() => {
        setCopied(false);
        res();
      }, timeout);
      setTimer(_timer);
    });
  }

  useEffect(() => {
    return () => timer && clearTimeout(timer);
  }, [timer])

  function close() {
    setCopied(false);
  }

  return {
    copied,
    copy,
    close,
    ref,
  }
}