import {DefaultButton, ITheme, mergeStyleSets, PrimaryButton, useTheme} from "@fluentui/react";
import {useMemo} from "react";

type DialogButtonsProps = {
  cancelText?: string,
  okText: string,
  onCancel?(): void,
  onOk(): void,
  enabled?: boolean
}

function buttonRowStyle(theme: ITheme,) {
  return mergeStyleSets({
    root: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "20px",
    }
  })
}

export function DialogButtons({cancelText, okText, onCancel, onOk, enabled}: DialogButtonsProps) {
  const theme = useTheme();
  const buttonRowStyled = useMemo(() => buttonRowStyle(theme), [theme]);

  return <div className={buttonRowStyled.root}>
    {onCancel
      && <DefaultButton onClick={onCancel}>{cancelText}</DefaultButton>
    }
      <PrimaryButton disabled={!enabled} onClick={onOk}>{okText} </PrimaryButton>
  </div>
}