import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
  // @ts-ignore
} from "react-simple-captcha";
import { DefaultButton, TextField, Stack, StackItem } from "@fluentui/react";
import { useEffect, useState, useCallback, FormEvent } from "react";
import {useTranslation} from "react-i18next";

interface CaptchaProps {
  onValidate: Function;
}

export function Captcha({ onValidate }: CaptchaProps) {
  const [fieldValue, setFieldValue] = useState("");
const {t} = useTranslation();

  const doSubmit = useCallback((newValue: string) => {
    if (validateCaptcha(newValue)) {
      onValidate(true);
    }
  }, []);

  const onChange = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      setFieldValue(newValue || "");
    },
    []
  );

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  return (
    <Stack>
      <LoadCanvasTemplate />
      <Stack
        horizontal={true}
        tokens={{ childrenGap: 20 }}
        verticalAlign={"center"}
      >
        <StackItem grow={3}>
          <TextField
            ariaLabel="enterCaptcha"
            value={fieldValue}
            required={true}
            onChange={onChange}
          />
        </StackItem>
        <StackItem grow={1}>
          <DefaultButton
            text={t("t:bom.validate")}
            onClick={() => doSubmit(fieldValue)}
          />
        </StackItem>
      </Stack>
    </Stack>
  );
}
