import {GuiTO} from "@encoway/c-services-js-client";
import {AxiosPromise} from "axios";
import {replace, slice} from "ramda";
import {Instance} from "../settings";
import {ProjectDTO} from "./bomService";

/**
 * Loads a Project and the configuration blob
 * @param uuid
 */
export function loadConfigurationFromProject(uuid: string): AxiosPromise<ProjectDTO> {
  return Instance.axios.get<ProjectDTO>(`/qcs-api/projects/${uuid}`);
}

/**
 * gets a custom gui view
 * @param configurationId
 * @param guiModel
 * @param language
 * @param mappingProfile
 */
export function getCustomConfigurationView(configurationId: string, guiModel: string, language: string, mappingProfile: string = "MINIMUM_CONTENT_MAPPING") {
  // de-AT will be "de" - custom hack for PSU Error will be fixed in 25.0.x
  const acceptLanguage = replace(/-[^-]+/, "", language)
  return Instance.axios.get<GuiTO>(
    `api/configuration/configurations/${configurationId}?mappingProfile=${mappingProfile}&guiModel=${guiModel}`,
    {
      headers: {
        "Accept-Language": acceptLanguage,
        "Accept": "application/vnd.encoway.conf.src.gui+json"
      }
    }
  );
}

/**
 * Gets the saved configuration
 * @param configurationId
 * @param language
 */
export async function getConfigurationBlob(configurationId: string, language: string): Promise<string> {
  const {data: {data}} = await Instance.axios.get<{ data: string }>(
    `api/configuration/configurations/${configurationId}`,
    {
      headers: {
        "Accept": "application/vnd.encoway.conf.save+json",
        "Accept-Language": slice(0, 2, language),
      }
    }
  );
  return data;
}