import {
  ITheme,
  Label,
  mergeStyleSets,
  Stack,
  useTheme,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useMemo, useState } from "react";
import { mediaQuery } from "../../../theme";
import { LineBreak } from "../../../components/LineBreak";
import { DialogButtons } from "../../../components/Dialog/DialogButtons";
import { BomMailState } from "./useContactRequest";
import { Captcha } from "../components/Captcha";
import { Bom } from "../../../types/configuration";
import { DynamicRequestContent } from "../../../components/DynamicRequestContent";

interface RequestFormProps {
  bomMail: BomMailState;

  requestFormParameter: Bom | undefined;

  onDismiss(): void;

  onChange(value: Partial<BomMailState>): void;

  onSend(): void;
}

function requestFormStyle(theme: ITheme) {
  return mergeStyleSets({
    root: {
      minWidth: "750px",
      padding: "40px",
      selectors: {
        [mediaQuery.lg]: {
          minWidth: "100%",
        },
      },
    },
    subHeading: {
      padding: "20px 40px 10px 40px",
    },
    input: {
      padding: "10px 0 0 0 0",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "20px",
      selectors: {
        "[role=alert]": {
          display: "none",
        },
        [mediaQuery.md]: {
          gridTemplateColumns: "1fr",
          gap: "5px",
        },
      },
    },
    inputPhone: {
      display: "grid",
      gap: "10px",
      gridTemplateColumns: ".4fr 1fr",
    },
    inputStreet: {
      display: "grid",
      gap: "10px",
      gridTemplateColumns: "1fr .4fr",
    },
    singleRow: {
      padding: "20px 0 0 0",
    },
    captcha: {
      padding: "10px 0 0 0 0",
    },
  });
}

export function RequestForm({onDismiss, onSend, onChange, requestFormParameter}: RequestFormProps) {
  const theme = useTheme();
  const bomStyled = useMemo(() => requestFormStyle(theme), [theme]);
  const {t} = useTranslation();
  const [validated, setValidated] = useState(false);
  const [infoMissing, setInfoMissing] = useState(false);

  return <Stack className={bomStyled.root}>
    <DynamicRequestContent requestContentParameter={requestFormParameter} onChange={onChange} bomStyled={bomStyled} setInfoMissing={setInfoMissing}/>
    <div className={bomStyled.captcha}>
      <Captcha onValidate={setValidated}/>
    </div>
    {infoMissing && <Label styles={{root:{color:"red"}}}>{t("t:bom.mandatory_info_missing")}</Label>}
    <LineBreak/>
    <DialogButtons
      cancelText={t("t:common.cancel")}
      okText={t("t:common.send")}
      onCancel={onDismiss}
      onOk={onSend}
      enabled={validated && !infoMissing}
    />
  </Stack>
}
