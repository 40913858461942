import {useContext} from "react";
import {ConfigurationContext} from "../../context/useConfiguration";
import {ComponentName, registerDefaultConfiguratorComponents} from "@encoway/cui-configurator-components";
import {Configurator as EncowayConfigurator} from "@encoway/react-configurator";
import {Navigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ConfTab} from "./components/ConfTab";
import {ConfSection} from "./components/ConfSection";
import {slice} from "ramda";
import {ConfParameter} from "./components/ConfParameter";
import {Stack} from "@fluentui/react";

registerDefaultConfiguratorComponents()
.override(ComponentName.DummyViewport, ConfTab)
.override(ComponentName.Section, ConfSection)
.override(ComponentName.Parameter, ConfParameter)
.override(ComponentName.Spinner, () => null)

export function Configurator() {
  const {cfg, eventBus} = useContext(ConfigurationContext);
  const {i18n} = useTranslation();

  if (cfg) {

    return <>
      <Stack.Item align="auto">
        <div id="cui" style={{flex: 1, position: "relative"}}>
          <EncowayConfigurator
            lang={slice(0, 2, i18n.language)}
            config={cfg}
            eventBus={eventBus}
            options={{
              dontDeleteOnIdChange: false,
              deleteOnUnmount: false,
              hideLinkedTree: true,
            }}
            styles={{
              section: {
                flex: 1,
                display: "flex",
                flexDirection: "row",
                overflow: "hidden"
              },
              main: {
                paddingBottom: "30px",
                flex: 1,
              },
              aside: {
                display: "flex",
                overflow: "auto"
              }
            }}
          />
        </div>
      </Stack.Item>
    </>
  }

  return <Navigate to="/"/>
}