import React, {createContext, ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {initReactI18next, useTranslation} from "react-i18next";
import {equals, path, reduce} from "ramda";
import {Settings} from "../settings";
import {fetchLanguages, fetchResource} from "../service/appService";
import {toLanguage} from "./appUtils";
import {ProductContext, ProductState} from "./useProduct";
import {ITheme} from "@fluentui/react/dist/react";
import {appTheme} from "../theme";
import {loadTheme, ThemeProvider} from "@fluentui/react";
import {PROGRESS} from "../hooks/useProgress";
import {Loading} from "../pages/layout/Loading";
import i18n from "i18next";
import {AppState, Translation} from "../types/app";

const initialState: AppState = {
  translations: {},
  languages: {[Settings.language.tag]: {[Settings.language.tag]: Settings.language}},
  theme: appTheme,
  userHelpText: "",
  progress: PROGRESS.NOT_LOADED
}

export const AppContext = createContext<AppState>(initialState);
export const AppProvider = AppContext.Provider;

const TRANSLATION_PATH = [Settings.studio.application.id, "characteristicValues", Settings.studio.application.translations, "values", 0, "uri"];
const THEME_PATH = [Settings.studio.application.id, "characteristicValues", Settings.studio.application.theme, "values", 0, "uri"];
const USERHELPTEXT_PATH = [Settings.studio.application.id, "characteristicValues", Settings.studio.application.userHelpText, "values", 0];

function useApp({getProducts}: ProductState): AppState {
  const {i18n} = useTranslation();
  const [app, setApp] = useState<AppState>(initialState);

  useEffect(() => {
    (async () => {
      if (!app.translations[i18n.language]) {
        const newLanguages = reduce(toLanguage, {}, await fetchLanguages(i18n.language));
        const products = await getProducts(Settings.studio.application.id);
        const languageUri = path<string>(TRANSLATION_PATH, products);
        const themeUri = path<string>(THEME_PATH, products);
        const userHelpText = path<string>(USERHELPTEXT_PATH, products);

        if (languageUri && themeUri) {
          const newTranslation = await fetchResource<Translation>(languageUri, i18n.language);
          const newTheme = await fetchResource<Partial<ITheme>>(themeUri, i18n.language);
          await i18n.addResourceBundle(i18n.language, "t", newTranslation);
          setApp({
            ...app,
            progress: PROGRESS.LOADED,
            languages: {
              ...app.languages,
              [i18n.language]: newLanguages
            },
            translations: {
              ...app.translations,
              [i18n.language]: newTranslation
            },
            theme: {
              ...app.theme,
              ...newTheme
            },
            userHelpText: userHelpText
          });
          //hack to switch cui configurator theme
          loadTheme(newTheme);
        } else {
          console.warn(`language ${i18n.language} is not set as language`);
        }
      }
    })();
  }, [getProducts]);

  return useMemo(() => app, [app, i18n.language]);
}


export function AppStore({children}: { children: ReactNode }) {
  const productStore = useContext(ProductContext);
  const appStore = useApp(productStore);

  if (equals(appStore.progress, PROGRESS.LOADED)) {
    return <AppProvider value={appStore}>
      <ThemeProvider theme={appStore.theme}>
        {children}
      </ThemeProvider>
    </AppProvider>
  }
  return <Loading/>;
}

i18n.use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: Settings.language.tag,
    interpolation: {
      escapeValue: false
    }
  })
  .then()

loadTheme(appTheme);