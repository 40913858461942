import {ReactNode, useMemo} from "react";
import ReactDOM from "react-dom";

export function Portal({id, children}: {id: string, children: ReactNode}) {
  const element = useMemo(() => document.getElementById(id), [id]);

  if(element) {
    return ReactDOM.createPortal(children, element);
  }
  return null;
}