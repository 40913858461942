import {Http} from "@encoway/c-services-js-client";
import axios from "axios";
import {isEmpty} from "ramda";
import {InstanceProps, SettingProps} from "./types/settings";

export const Settings: SettingProps = {
  imagePath: "api/catalog/media?id=",
  language: {
    tag: "de-DE",
    displayName: "Deutsch"
  },
  showroom: {
    //@ts-ignore
    url: window.encoway?.cpqBaseUrl || process.env.REACT_APP_BACKEND_URL || "/apetito-server",
    auth: {
      password: "WTvg=C3^dcx=xy3H",
      username: "services",
    },
  },
  studio: {
    boms: {
      view: "encoway.customcalculationview",
      overview: "overview",
      form: "mailform",
      noProduct: ["NO_VALUE"],
    },
    application: {
      id: "application",
      theme: "theme",
      translations: "translations",
      userHelpText: "userHelpText"
    },
  },
  customizing: {
    noParameterTO: [
      "checkBox"
    ]
  }
};

export const Instance: InstanceProps = {
  axios: axios.create({
    ...!isEmpty(Settings.showroom.url) && {baseURL: Settings.showroom.url},
    auth: {
      username: Settings.showroom.auth.username,
      password: Settings.showroom.auth.password
    }
  }),
  http: Http.Basic(Settings.showroom.auth.username, Settings.showroom.auth.password),
}