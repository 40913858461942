import {useParams} from "react-router-dom";
import {Dispatch, SetStateAction, useContext, useState} from "react";
import {ConfigurationContext} from "../../../context/useConfiguration";
import {useTranslation} from "react-i18next";
import {PROGRESS, ProgressStore, useProgress} from "../../../hooks/useProgress";
import {sendMail, sendProject} from "../../../service/bomService";
import {ConfigurationRoutes} from "../../../types/routes";

export type BomMailState = { [key: string]: string | undefined };

type BomMailStore = {
  mailInputs: BomMailState;
  mailOpen: boolean;
  required: boolean;
  progress: ProgressStore;
  emailSend: boolean;
  setEmailSend(bool: boolean): void;
  setOpenMail: Dispatch<SetStateAction<boolean>>;
  setMailInputs: Dispatch<SetStateAction<BomMailState>>;
  onSend(): void;
  onSave(): void;
};

const REGEX_TEST_MAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function useContactRequest(): BomMailStore {
  const params = useParams<ConfigurationRoutes>();
  const { cfg } = useContext(ConfigurationContext);
  const { i18n } = useTranslation();

  const [mailOpen, setOpenMail] = useState<boolean>(false);
  const [mailInputs, setMailInputs] = useState<BomMailState>({});
  const [required, setRequired] = useState<boolean>(false);
  const [emailSend, setEmailSend] = useState<boolean>(false);
  const bomSaveProgress = useProgress();

  function onSend() {
    sendMail(
      params.article!,
      cfg!.id(),
      i18n.language,
      mailInputs,
      false
    ).then(() => {
    });
    setEmailSend(true);
    setEmailSend(true);setOpenMail(false);
  }

  function onSave() {
    bomSaveProgress.set(PROGRESS.LOADING);
    sendProject(
      params.article!,
      cfg!.id(),
      i18n.language
    ).then(({ projectId }) => bomSaveProgress.set(PROGRESS.LOADED, projectId));
  }

  return {
    mailInputs,
    mailOpen,
    required,
    progress: bomSaveProgress,
    setOpenMail,
    setMailInputs,
    emailSend,
    setEmailSend,
    onSend,
    onSave,
  };
}
