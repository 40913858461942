import React, {createContext, ReactNode, useEffect, useMemo, useState,} from "react";
import {ConfigurationService, GuiTO} from "@encoway/c-services-js-client";
import {Constants} from "@encoway/react-configurator";
import {L10n} from "@encoway/l10n";
import {useTranslation} from "react-i18next";
import {equals, mergeDeepRight, reduce} from "ramda";
import {Bus} from "baconjs";
import {translations as Tapp} from "@encoway/cui-application-components";
import {translations as Tconf} from "@encoway/cui-configurator-components";
import {toBom, toSelectedParameters} from "./configurationUtils";
import {getCustomConfigurationView, loadConfigurationFromProject,} from "../service/configurationService";
import {Instance, Settings} from "../settings";
import {Bom, CfgState, Configuration, ConfigurationState, SalesContext, SelectedParameters,} from "../types/configuration";

const initialCfg: CfgState = {
    guiTO: undefined,
    cfg: undefined,
    boms: {},
};

const initialStore: ConfigurationState = {
    guiTO: undefined,
    cfg: undefined,
    bom: [],
    eventBus: undefined,
    actions: {
        loadSession: async function () {
            throw new Error("load not initialized");
        },
        start: async function () {
            throw new Error("start not initialized");
        },
        selectedParameters: function () {
            throw new Error("selectedParameters not initialized");
        },
        loadSaved: function () {
            throw new Error("loadSaved not initialized");
        },
    },
};

export const ConfigurationContext = createContext<ConfigurationState>(
    initialStore
);
export const ConfigurationProvider = ConfigurationContext.Provider;

const translations = mergeDeepRight(Tapp, Tconf);
L10n.source("Configuration", translations, true);

function useConfiguration(): ConfigurationState {
    const {i18n} = useTranslation();
    const eventBus = useMemo(() => new Bus(), []);
    const [{cfg, guiTO, boms}, setConfiguration] = useState<CfgState>(
        initialCfg
    );

    async function getBoms(
        configurationId: string
    ): Promise<{ [lang: string]: Bom[] }> {
        const fetchedBom = await getCustomConfigurationView(
            configurationId,
            Settings.studio.boms.view,
            i18n.language,
            "MAXIMUM_CONTENT_MAPPING"
        );
        return {
            [i18n.language]: reduce(toBom, [], [fetchedBom.data.rootContainer]),
        };
    }

    async function start(
        articleName: string,
        salesContext?: SalesContext
    ): Promise<Configuration> {
        const configuration = await ConfigurationService.create(
            Instance.http,
            Settings.showroom.url,
            {
                articleName: articleName,
                ...(salesContext && {salesContext}),
            },
            i18n.language
        );
        configuration.settings({
            mappingOptions: {
                mappingProfile: "MAXIMUM_CONTENT_MAPPING",
            },
        });
        let _guiTO = await configuration.ui();
        const _boms = await getBoms(configuration.configurationId);
        const config: CfgState = {guiTO: _guiTO as GuiTO, cfg: configuration, boms: _boms};
        setConfiguration(config);
        return {guiTO: _guiTO as GuiTO, cfg: configuration};
    }

    async function loadSession(confId: string): Promise<Configuration> {
        const configuration = await ConfigurationService.create(
            Instance.http,
            Settings.showroom.url,
            {configurationId: confId},
            i18n.language
        );
        configuration.settings({
            mappingOptions: {
                mappingProfile: "MAXIMUM_CONTENT_MAPPING",
            },
        });
        const _guiTO = await configuration.ui();
        const _boms = await getBoms(configuration.configurationId);
        const config = {guiTO: _guiTO as GuiTO, cfg: configuration, boms: _boms};
        setConfiguration(config);
        return {guiTO: _guiTO as GuiTO, cfg: configuration};
    }

    async function loadSaved(uuid: string): Promise<Configuration> {
        const {data} = await loadConfigurationFromProject(uuid);
        const configuration = await ConfigurationService.load(
            Instance.http,
            Settings.showroom.url,
            {
                data: data.solution,
                articleName: data.projectName,
            },
            i18n.language
        );
        configuration.settings({
            mappingOptions: {
                mappingProfile: "MAXIMUM_CONTENT_MAPPING",
            },
        });
        const _guiTO = await configuration.ui();
        const _boms = await getBoms(configuration.configurationId);
        const config = {guiTO: _guiTO as GuiTO, cfg: configuration, boms: _boms};
        setConfiguration(config);
        return {guiTO: _guiTO as GuiTO, cfg: configuration};
    }

    function selectedParameters(_guiTO?: GuiTO): SelectedParameters {
        const internalGuiTO = _guiTO || guiTO;
        if (internalGuiTO?.rootContainer.children[0]) {
            return reduce(
                toSelectedParameters,
                {},
                internalGuiTO.rootContainer.children[0].parameters
            );
        }
        throw new Error("Could not get selected parameters. GuiTO is undefined");
    }

    useEffect(() => {
        return eventBus.onValue(async (e: any) => {
            if (equals(e.event, Constants.Events.UpdateState)) {
                const _guiTO = e.rawState as GuiTO;
                if (cfg) {
                    const _boms = await getBoms(cfg.configurationId);
                    setConfiguration((prev) => ({
                        ...prev,
                        cfg: prev.cfg,
                        guiTO: _guiTO,
                        boms: _boms,
                    }));
                } else {
                    throw new Error(
                        "cfg is not defined, trying to change language not possible in configurator context"
                    );
                }
            }
        });
    }, [eventBus, cfg]);

    useEffect(() => {
        if (cfg) {
            // Subs even if configurator isn't rendered
            const id = setInterval(() => cfg.status(), 60 * 2000);
            return () => clearInterval(id);
        }
    }, [cfg]);

    useEffect(() => {
        L10n.reloadResources(i18n.language).then(() =>
            L10n.currentLocale(i18n.language)
        );
    }, [i18n.language]);

    return useMemo(
        () => ({
            cfg,
            guiTO,
            eventBus,
            bom: boms[i18n.language],
            actions: {
                start,
                loadSession,
                loadSaved,
                selectedParameters,
            },
        }),
        [cfg, eventBus, boms, guiTO, i18n.language]
    );
}

export function ConfigurationStore({children}: { children: ReactNode }) {
    return (
        <ConfigurationProvider value={useConfiguration()}>
            {children}
        </ConfigurationProvider>
    );
}
